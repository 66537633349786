import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { css } from '@emotion/react'
import { mobile, tablet, UnderlineLink } from '~styles/global'
import Seo from '~components/Seo'
import RichText from '~components/RichText'
import DataViewer from '~utils/DataViewer'
import styled from '@emotion/styled'
import ContactForm from '~components/ContactForm'
import ContactFormSendGrid from '~components/ContactFormSendGrid'
import SanityLink from '../components/SanityLink'

const ContactPage = ({ data }) => {
	const { title, content } = data.page
	return (
		<>
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<Wrap>
				<Panel>
					<Top>
						<Title>{title}</Title>
						<Text className='h1'><RichText content={content.text}/></Text>
					</Top>
					<Connect className='h1'>
						<div>
							<Email link={{
								url: `mailto:${content.email}`
							}}>{content.email}</Email>
						</div>
						<div>
							<Insta link={{
								url: `https://www.instagram.com/${content.instagramHandle}`,
								blank: true
							}}>@{content.instagramHandle}</Insta>
						</div>
					</Connect>
				</Panel>
				<Panel>
					{/* <Form successMessage={content?.contactSuccessMessage} formName="Contact" /> */}
					<Form2 
						successMessage={content?.contactSuccessMessage} 
						email={content?.contactEmail} 
						formName="Contact" 
					/>
				</Panel>
			</Wrap>
			<DataViewer data={data} name="data" />
		</>
	)
}

const Wrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	${mobile}{
		grid-template-columns: 1fr;
	}
`
const Panel = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: 40px;
	padding: var(--xxxl) 40px;
	&:first-of-type{
		border-right: 1px solid var(--black);
	}
	${mobile}{
		padding: var(--xxxl) 20px;
		grid-column-gap: 20px;
		&:first-of-type{
			border-bottom: 1px solid var(--black);
			padding-right: 20px;
			border-right: none;
		}
	}
`
const Top = styled.div`
	align-self: flex-start;
	grid-column: span 6;
	${mobile}{
		margin-bottom: var(--xl)
	}
`
const Title = styled.h3`
	text-align: center;
	margin-bottom: var(--l);
`
const Text = styled.div`
	text-align: center;
	padding: 0 7%;
	${tablet}{
		padding: 0;
	}
`
const Connect = styled.div`
	align-self: end;
	text-align: center;
	grid-column: span 6;
`
const Insta = styled(UnderlineLink)`
	display: inline-block;
	${tablet}{
		&:after{
			bottom: 0.18em;
		}
	}
`
const Email = styled(Insta)`
	margin-bottom: var(--m);
`
const Form = styled(ContactForm)`
	grid-column: 2/6;
	min-height: 600px;
	${tablet}{
		grid-column: 1/7;
		min-height: 0;
	}
`
const Form2 = styled(ContactFormSendGrid)`
	grid-column: 2/6;
	min-height: 600px;
	${tablet}{
		grid-column: 1/7;
		min-height: 0;
	}
`

ContactPage.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query ContactPageQuery {
		page: sanityContactPage {
			title
			content {
				seo {
					...seo
				}
				email
				instagramHandle
				text: _rawText
				contactSuccessMessage
				contactEmail
			}
		}
	}
`

export default ContactPage
