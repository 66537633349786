import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Tick } from '~components/Svg'
import { tablet } from '~styles/global'

const Checkbox = ({ className, id, initialChecked, children, onChange, hiddenName }) => {

	const [isChecked, setIsChecked] = useState(initialChecked ?? false)

	useEffect(() => {
		setIsChecked(initialChecked)
	}, [initialChecked])

	const handleChange = () => {
		setIsChecked(!isChecked)
	}

	useEffect(() => {
		if(onChange){
			onChange(isChecked)
		}
	}, [isChecked])

	return (
		<div className={className}>
			<Input type="Checkbox" id={id} onChange={() => handleChange()} checked={initialChecked}/>
			<input type="hidden" value={isChecked} name={hiddenName}/>
			<Label htmlFor={id} css={css`
          &:before{
            background: ${isChecked ? 'black' : 'rgba(0, 0, 0, 0,)'};
          }
        `}>
				<span className="h1">{children} <Tick ticked={isChecked}/></span>
			</Label>
		</div>
	)
}

const Input = styled.input`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`

const Label = styled.label`
  display: inline-block;
  cursor: pointer;
  position: relative;
	svg{
		width: 20px;
		${tablet}{
			width: 13px;
		}
	}
`

Checkbox.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	initialChecked: PropTypes.bool,
	label: PropTypes.node
}

Checkbox.defaultProps = {
	id: 'example',
	name: 'example',
	initialChecked: false,
	children: 'Subscribe to our newsletter to receive news about promotions, products and more.'
}

export default Checkbox
