import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Checkbox from './Checkbox'
import sendToMailchimp from '../utils/sendToMailchimp'
import encode from '../utils/encode'
import Button from '~components/Button'

const ContactForm = ({ className, formName, successMessage }) => {
  
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)
	const [netlifyError, setNetlifyError] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		const data = encode({'form-name': formName, ...values})
		if(window.location.hostname === 'localhost'){
			setSuccess(true)
			if(values.email && values.newsletter){
				sendToMailchimp(values.email, null, null, (err, msg) => console.log(err,msg))
			}
		} else {
			axios.post(window.location.pathname, data, {
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			}).then(res => {
				setSuccess(true)
				if(values.email && values.newsletter){
					sendToMailchimp(values.email)
				}
			})
				.catch(err =>
					setNetlifyError(true)
				)
		}
	}

	const errorFields = errors.map(error => error.field)

	return (
		<div className={className} css={css`
          position: relative;
      `}>
			<Success css={css`
          opacity: ${success ? '1' : '0'};
          pointer-events: ${success ? 'all' : 'none'};
        `}>
				{successMessage}
			</Success>
			<Form className="h2" method="POST" data-netlify="true" name={formName} noValidate onSubmit={e => validate(e)} css={css`
					opacity: ${success ? '0' : '1'};
					pointer-events: ${success ? 'none' : 'all'};
				`}>
				<input type="hidden" name="form-name" value={formName} />
				<input
					type="text"
					placeholder="Name"
					id="name"
					name="name"
					onChange={e => setValues({...values, name: e.target.value})}
				/>
				<input
					type="email"
					id="email"
					name="email"
					css={css`
              border-color: ${errorFields.includes('email') ? 'var(--gold)' : 'black'} ;
            `}
					placeholder="Email*"
					onChange={e => setValues({...values, email: e.target.value})}
				/>
				<Textarea name="message" id="message"
					onChange={e => setValues({...values, message: e.target.value})}
					placeholder="Message"
					css={css`
             grid-column: span 2;
             height: 200px;
             `
					} />
				{errors?.length > 0 &&
            <Errors>
            	{errors.map(error => <p className="h2">{error.message}</p>)}
            </Errors>
				}
				<Tickbox id="subscribe-contact" name="checkbox" hiddenName="newsletter" onChange={value => setValues({...values, newsletter: value})}>
					Subscribe?
				</Tickbox>
				<Submit onClick={() => null} type="submit" largeArrow={true}>
					<span className="h1">Submit</span>
				</Submit>
			</Form>
		</div>
	)
}

const Success = styled.h3`
  position: absolute;
  top: 0;
  left: 0;
	right: 0;
  transition: opacity 0.3s 0.3s;
	text-align: center;

`

const Form = styled.form`
  transition: opacity 0.3s;
	display: block;
  input, textarea{
    display: block;
  }
`

const Textarea = styled.textarea`
	margin-top: var(--xl);
`

const Errors = styled.div`
  color: var(--gold);
	text-align: center;
`

const Tickbox = styled(Checkbox)`
	margin-top: var(--l);
	text-align: center;
`
const Submit = styled(Button)`
	margin: var(--m) auto 0;
	display: flex;
`

ContactForm.propTypes = {
	formName: PropTypes.string.isRequired,
	className: PropTypes.string,
	successMessage: PropTypes.array,
}

export default ContactForm
